import React from 'react';

import { useForm } from '../../../hooks/useForm';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, FormControl, NativeSelect, FormHelperText, Grid, Select, Input, MenuItem, Collapse, ListItem, Chip, Checkbox, IconButton } from '@material-ui/core';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { trowError, trowErrorMessage } from '../../../utils/errors';
import { tableSelector, formIsError } from '../../../redux/tableReducer';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../Common/Preloader';
import { packageSelector } from '../../../redux/packageReducer';
import { useStyles } from './styles';
import SwitchStatus from '../../Common/SwitchStatus';
import ErrorText from '../../Common/ErrorText';
import { IPackage, IGame, TableData } from '../../../interfaces';
import { TabItem } from '../../Halls/View/styles';
import JackpotsList from '../../Jackpots/List';
import { hallSelector } from '../../../redux/hallReducer';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../../redux/authReducer';
import Denominations from './denominations';
import { findPermission } from '../../../utils/find';

interface TableFormProps {
  onSubmit: (
    data: TableData,
    packages: any,
    hideForm: () => void,
    id?: number,
    info?: boolean
  ) => void;
  name?: string;
  id?: number | undefined;
  hall_id?: number;
  packets?: IPackage[];
  status?: boolean;
  cancelBtnText: string;
  cancelBtnEvent: () => void;
  submitBtnText: string;
  hideForm: () => void;
  defaultHall?: number;
  setJackpotId?: (id: number) => void;
  showJackpot?: () => void;
  showAddJackpot?: () => void;
  showChangeJackpot?: () => void;
  tab: number;
  setTab: (id: number) => void;
  denomination?: number;
  denominationList?: number[] | null;
  denom?: string;
}

const TableForm: React.FC<TableFormProps> = ({
  onSubmit,
  name = '',
  id,
  hall_id = '',
  packets = [],
  status = false,
  cancelBtnText,
  cancelBtnEvent,
  submitBtnText,
  hideForm,
  defaultHall,
  tab,
  setTab, denomination, denominationList
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(tableSelector)
  const {
    packages: { items: packages },
  } = useSelector(packageSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const { role: { item: userRole }, assigments: { items: assigments } } = useSelector(authSelector)

  const [packagesSelected, setPackagesSelected] = React.useState<number[]>([])
  const [gamesSelected, setGamesSelected] = React.useState<GamesType[]>([])

  const getGamesId = (games: IGame[]) => {
    let arr: number[] = [];
    games.forEach((item: IGame) => {
      arr = [...arr, item.id];
    });
    return arr;
  }
  type GamesType = {
    package_id: number
    isOpen: boolean | undefined
    games: number[]
  }
  React.useEffect(() => {
    let packages: number[] = [], games: GamesType[] = [];
    packets.forEach((item: IPackage) => {
      packages = [...packages, item.package.id];
      games = [...games, { package_id: item.package.id, isOpen: false, games: getGamesId(item.games) }];
    });
    packages && setPackagesSelected(packages);
    games && setGamesSelected(games);
    // eslint-disable-next-line
  }, []);

  const handlerOpenPackage = (arr: GamesType[], package_id: number) => {
    let itemId = arr.findIndex((i) => i.package_id === package_id);
    setGamesSelected([
      ...arr.slice(0, itemId),
      {
        package_id: arr[itemId].package_id,
        isOpen: !arr[itemId].isOpen,
        games: arr[itemId].games
      },
      ...arr.slice(itemId + 1, arr.length)
    ])
  }

  const handlerCheckGame = (arr: GamesType[], package_id: number, game_id: number) => {
    let itemId = arr.findIndex((i: GamesType) => i.package_id === package_id)
    const changeGamesArray = (games: number[], game: number) => {
      if (games.includes(game)) {
        let index = games.indexOf(game)
        return [...games.slice(0, index), ...games.slice(index + 1, games.length)]
      } else {
        return [...games, game]
      }
    }
    setGamesSelected([
      ...arr.slice(0, itemId),
      {
        package_id: arr[itemId].package_id,
        isOpen: arr[itemId].isOpen,
        games: changeGamesArray(arr[itemId].games, game_id),
      },
      ...arr.slice(itemId + 1, arr.length),
    ])
  }

  const canViewGamePackages = findPermission(assigments, 'viewGamePackageList');
  const getPackagesData = () => {
    if(!canViewGamePackages) {
      return null;
    }
    let data: any = []

    packagesSelected.forEach((item: number) => {
      const games: any = gamesSelected?.find((i: GamesType) => i.package_id === item)?.games
      data = [
        ...data,
        {
          package: {
            id: item,
          },
          games: [...games],
        },
      ]
    })
    return {
      packages: data,
    }
  }

  const { form, handleInputChange, setFormItem } = useForm({
    name: name,
    hall_id: defaultHall ? defaultHall : hall_id,
    password: '',
    status, denomination, denominationList, denom: ''
  })

  const data: TableData = {
    name: form.name,
    hall_id: form.hall_id,
    login: '',
    password: form.password,
    status: form.status ? 10 : 0,
    denomination: form.denomination,
    denominations: form.denominationList
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, getPackagesData(), hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  const hall = halls.find(v => v.id === hall_id);
  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main()
      case 1:
        return packetsRender()
      case 2:
        return <JackpotsList table_id={id} />
      case 5:
        return <Denominations setDenom={(x: string) => setFormItem('denom', x)} setDenominations={(x: number[] | null) => setFormItem('denominationList', x)} form={form} error={error} hallDenominations={(hall?.denominations || []).map(e => +e)} setDefaultDenomination={(x: number) => setFormItem('denomination', x)} />
      default:
        setTab(2)
    }
  }

  const main = () => {
    return <>
      <Grid item xs={12} sm={7}>
        <TextField className={classes.textFields} label={t('ru.tables.fields.name')} type="text" value={form.name} name="name" onChange={handleInputChange} error={!!trowError(error, 'name')} helperText={trowErrorMessage(error, 'name')} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl error={!!trowError(error, 'hall_id')}>
          <NativeSelect className={classes.select} value={form.hall_id} onChange={(e) => setFormItem('hall_id', e.target.value)} disabled={!!defaultHall}>
            <option value=""></option>
            {halls && halls.map((item: any) => <option key={item.id} value={item.id}> {item.name} </option>)}
          </NativeSelect>
          <FormHelperText>
            {trowErrorMessage(error, 'hall_id', t('ru.tables.form_helpers.hall'))}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box className={classes.textFields} display="flex" justifyContent="flex-start" alignItems="center" onClick={() => setFormItem('status', !form.status)} style={{ cursor: 'pointer' }}>
          <SwitchStatus checked={!!form.status} />
        </Box>
      </Grid>
      {id && <Grid item xs={12} sm={12}>
        <TextField className={classes.textFields} label={t('ru.tables.fields.password')} type="text" value={form.password} name="password" onChange={handleInputChange} error={!!trowError(error, 'password')} helperText={trowErrorMessage(error, 'password')} />
      </Grid>}
    </>
  }

  const packetsRender = () => {
    return (
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth size="small">
          <FormHelperText>{t('ru.tables.form_helpers.packets')}</FormHelperText>
          <Select multiple value={packagesSelected} input={<Input />}>
            {packages &&
              packages.map((item: IPackage) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => {
                    if (packagesSelected.includes(item.id)) {
                      let index = packagesSelected.indexOf(item.id)
                      setPackagesSelected([
                        ...packagesSelected.slice(0, index),
                        ...packagesSelected.slice(index + 1, packagesSelected.length),
                      ])
                    } else {
                      setPackagesSelected([...packagesSelected, item.id])
                    }
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {packagesSelected.map((item) => {
            const packageItem = packages && packages.find((i: IPackage) => i.id === item)
            !!packageItem &&
              !gamesSelected.find((i) => i.package_id === packageItem.id) &&
              setGamesSelected([
                ...gamesSelected,
                {
                  package_id: packageItem.id,
                  isOpen: false,
                  games: getGamesId(packageItem.games),
                },
              ])

            return (
              packageItem && (
                <React.Fragment key={item}>
                  <ListItem
                    button
                    onClick={() => handlerOpenPackage(gamesSelected, packageItem.id)}
                    className={classes.packet}
                  >
                    <Box display="flex" alignItems="center">
                      <BorderOuterIcon className={classes.icon} />
                      {packageItem.name}
                      {gamesSelected.find((i) => i.package_id === packageItem.id) &&
                        gamesSelected?.find((i) => i.package_id === packageItem.id)?.isOpen ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Box>
                    <IconButton
                      onClick={() => {
                        let index = packagesSelected.indexOf(packageItem.id)
                        setPackagesSelected([
                          ...packagesSelected.slice(0, index),
                          ...packagesSelected.slice(index + 1, packagesSelected.length),
                        ])
                      }}
                      style={{ padding: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItem>
                  <Collapse
                    in={
                      gamesSelected.find((i) => i.package_id === packageItem.id) &&
                      gamesSelected?.find((i) => i.package_id === packageItem.id)?.isOpen
                    }
                  >
                    <Box className={classes.chips}>
                      {packageItem.games.map((game: IGame) => {
                        return (
                          <Chip
                            className={classes.chip}
                            key={game.id}
                            onClick={() => handlerCheckGame(gamesSelected, packageItem.id, game.id)}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <span>{game.name}</span>
                                <Checkbox
                                  checked={
                                    gamesSelected.find((i) => i.package_id === packageItem.id) &&
                                    gamesSelected
                                      ?.find((i: GamesType) => i.package_id === packageItem.id)
                                      ?.games.includes(game.id)
                                  }
                                  color="default"
                                />
                              </Box>
                            }
                          />
                        )
                      })}
                    </Box>
                  </Collapse>
                </React.Fragment>
              )
            )
          })}
        </FormControl>
      </Grid>
    )
  }

  return formLoading ? <Preloader absolute={false} /> :
    <form onSubmit={(e) => { e.preventDefault(); onSubmit(data, getPackagesData(), hideForm, id); }}>
      <Box className="tabs" display="flex" justifyContent="flex-start" style={{ overflowX: 'auto', marginBottom: '15px' }}>
        <TabItem active={tab === 0} onClick={() => setTab(0)}>
          {t('ru.tables.tabs.info')}
        </TabItem>
        <TabItem active={tab === 5} onClick={() => setTab(5)}>
          {t('ru.tables.tabs.denomination')}
        </TabItem>
        {canViewGamePackages && <TabItem active={tab === 1} onClick={() => setTab(1)}>
          {t('ru.tables.tabs.packets')}
        </TabItem>}
        {id && userRole && userRole !== 'cashier' && <TabItem active={tab === 2} onClick={() => setTab(2)}>
          {t('ru.tables.tabs.jackpots')}
        </TabItem>}
      </Box>
      <Grid container spacing={3}>
        {returnTab(tab)}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button fullWidth className={classes.submitBtn} variant="contained" onClick={cancelBtnEvent}>
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
    </form>
}

export default TableForm
