import axios from 'axios'
import { TableData, IPaymentData } from '../interfaces'

export const fetchGamblingTableList = async (page: number, limit: number, search: any = {
  id: '',
  name: '',
  login: '',
  hall_id: ''
}, sort?: string) => {
  return axios.get(`gambling-table`, {
    params: {
      page: page,
      'per-page': limit,
      expand: 'login,currentGame,playerConnection',
      'f[id]': search['id'],
      'f[name]': search['name'],
      'f[login]': search['login'],
      'f[hall_id]': search['hall_id'],
      sort: sort
    }
  })
}

export const addGamblingTable = async (data: TableData) => {
  return axios.post(`gambling-table`, data, {
    params: { expand: 'hall,packages,login,password,currentGame,playerConnection,denominationList' },
  })
}

export const fetchGamblingTable = async (id: number) => {
  return axios.get(`gambling-table/${id}`, {
    params: { expand: 'hall,packages,login,password,currentGame,playerConnection,denominationList' },
  })
}

export const changeGamblingTable = async (data: TableData, id: number) => {
  return axios.put(`gambling-table/${id}`, data, {
    params: { expand: 'hall,packages,login,password,currentGame,playerConnection,denominationList' },
  })
}

export const deleteGamblingTable = async (id: number) => {
  return axios.delete(`gambling-table/${id}`)
}

export const AddPackageToGamblingTable = async (data: TableData, table_id: number) => {
  return axios.put(`gambling-table/${table_id}/packages`, data, {
    params: { expand: 'hall,packages,login,password,currentGame' },
  })
}

export const takeGamblingTableBalance = async (data: IPaymentData, id: number) => {
  return axios.post(`gambling-table/${id}/balance/take`, data)
}

export const putGamblingTableBalance = async (data: IPaymentData, id: number) => {
  let extra = data.extra ? `?extra=${data.extra}` : '';
  return axios.post(`gambling-table/${id}/balance/put${extra}`, data)
}

export const fetchGamblingTableSessions = async (id: number, page: number, limit: number) => {
  return axios.get(`gambling-table/${id}/session`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const fetchSessions = async (session_id: number, page: number, limit: number) => {
  return axios.get(`session/${session_id}/spins`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const fetchSessionSpin = async (spin_id: number, page: number, limit: number) => {
  return axios.get(`session/spin/${spin_id}`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const closeSession = async (table_id: number) => {
  return axios.get(`gambling-table/${table_id}/close-game-session`)
}

export const setMathType = async (table_id: number, mathType: number) => {
  return axios.post(`gambling-table/${table_id}/set-math/${mathType}`)
}

export const putTableHighscore = async (table_id: number, amount: number) => {
  return axios.post(`gambling-table/${table_id}/highscore/put`, { amount: amount })
}
//data: {
//     amount:
// }
export const takeTableHighscore = async (table_id: number) => {
  return axios.post(`gambling-table/${table_id}/highscore/take`)
}

// export const putTablePollscore = async (table_id: number) => {
//     return axios.get(`gambling-table/${table_id}/pollscore/put`)
// }

export const takeTablePollscore = async (table_id: number, currency_id: string | undefined, amount: number) => {
  return axios.post(`gambling-table/${table_id}/pollscore/take`, { currency_id, amount })
}

export const getTableOperations = async (table_id: number, page: number, limit: number) => {
  return axios.get(`gambling-table/${table_id}/balance/operations`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const resetTableMath = async (table_id: number) => {
  return axios.post(`gambling-table/${table_id}/math/reset`)
}

export const getTableMathChanges = async (data: any, table_id: number) => {
  return axios.post(`gambling-table/${table_id}/math`, data)
}


export const changeTableBank = async (data: any, table_id: number) => {
  return axios.post(`gambling-table/${table_id}/math/bank`, data)
}
export const getTableBankReport = async (table_id: number, page: number, limit: number) => {
  return axios.post(`gambling-table/${table_id}/bank/report`, {}, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const tableCmdHome = async (table_id: number) => {
  return axios.post(`gambling-table/${table_id}/send-cmd/home`)
}

export const tableCmdReload = async (table_id: number) => {
  return axios.post(`gambling-table/${table_id}/send-cmd/reload`)
}


// v1/gambling-table/<id:\d+>/send-cmd/home - на главную
// v1/gambling-table/<id:\d+>/send-cmd/reload - перезагрузка
// POST v1/gambling-table/<id:\d+>/bank/report
// История операций по банку стола