import React from 'react'

import List from '@material-ui/core/List'
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  Chip,
  Collapse,
  ListItem,
  Button,
  Tooltip,
} from '@material-ui/core'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'

import { Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  tableSelector,
  getTable,
  tableSuccess,
  handlerChangeTable,
} from '../../../redux/tableReducer'
import Preloader from '../../Common/Preloader'
import { TabItem } from './styles'
import { IGame, IPackage } from '../../../interfaces'
import AvTimerIcon from '@material-ui/icons/AvTimer'
import { prettyNum } from '../../../utils/prettyNum'
import TableReports from './Reports'
import { getTableReports } from '../../../redux/reportReducer'
import JackpotsList from '../../Jackpots/List'
import { Status } from '../../Users/List/styles'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import TableForm from '../Form'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formIsError } from '../../../redux/jackpotReducer'
import { convertUTCDateToLocalDate } from '../../../utils/date'
import { authSelector } from '../../../redux/authReducer'
import GoBackBtn from '../../Common/GoBackBtn'
import EnhanceLink from '../../Common/EnhanceLink'
import { findPermission, findPermissionOr } from '../../../utils/find'
import Denominations from './denominations';

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  backBtn: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  balance: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#919191',
    padding: '3px',
    fontWeight: 'bold',
    width: 'max-content',
  },
  img: {
    width: 'auto',
    height: '30vh',
  },
}))

interface TableProps {
  hideTable: () => void
  showTableSessions: () => void
  showOperations: () => void
  tableId: number
  isChangeMode?: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
  showPayment: () => void
}

const Table: React.FC<TableProps> = ({
  tableId,
  isChangeMode,
  setIsChangeMode,
  showPayment
}) => {
  const classes: any = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location: any = useLocation()
  const [t] = useTranslation()

  const { data: table, loading } = useSelector(tableSelector)
  const {
    role: { item: userRole },
    assigments: { items },
  } = useSelector(authSelector)

  React.useEffect(() => {
    tableId && dispatch(getTable(tableId))
    return () => {
      dispatch(tableSuccess(null))
    }
  }, [dispatch, tableId])

  React.useEffect(() => {
    // getTableBankReport(tableId).then(res=> console.log(res))
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [dispatch])

  const [tab, setTab] = React.useState(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main();
      case 1:
        return packets();
      case 2:
        return <JackpotsList change table_id={tableId} />;
      case 4:
        return <TableReports tableId={tableId} fetch={(id: number, from: string, to: string) => dispatch(getTableReports(id, from, to))} currency_id={table?.hall.currency_id} />;
      case 5:
        return table && <Denominations table={table} />;
      default:
        break;
    }
  }

  const currency_id = table?.hall?.currency_id;
  const main = () => <>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.tables.fields.name')}:</b> {table?.name}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.tables.fields.hall')}:</b>{' '}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${table?.hall.id}`}>
              {table?.hall?.name}
            </EnhanceLink>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.tables.fields.login')}:</b> {table?.login}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.tables.fields.password')}:</b> {table?.password}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            onClick={() => {
              showPayment()
            }}
          >
            <b>{t('ru.tables.fields.balance')}:</b>{' '}
            <span className={classes.balance}>
              {!!currency_id && prettyNum(table?.balance[currency_id])}({currency_id})
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.tables.fields.denomination')}:</b> {!!table && table.denomination / 100}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <b>{t('ru.tables.fields.created_at')}:</b>
            {!!table && (
              <Box
                style={{ width: '100%' }}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
              >
                <span style={{ margin: '3px' }}>{convertUTCDateToLocalDate(table.created_at)}</span>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <b>{t('ru.tables.fields.connection_time')}:</b>
            {!!table && (
              <Box
                style={{ width: '100%' }}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
              >
                <span style={{ margin: '3px' }}>
                  {convertUTCDateToLocalDate(table.connection_time)}
                </span>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center">
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                history.push({
                  pathname: location.pathname,
                  search: location.search + '&sessions_page=1',
                  state: {
                    prevPaths:
                      location.state && location.state.prevPaths ? location.state.prevPaths : [],
                  },
                })
              }}
              style={{ margin: '10px' }}
            >
              {t('ru.common.history_sessions_btn')} <AvTimerIcon style={{ margin: '0 10px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="center">
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                history.push({
                  pathname: location.pathname,
                  search: location.search + '&operations_page=1',
                  state: {
                    prevPaths:
                      location.state && location.state.prevPaths ? location.state.prevPaths : [],
                  },
                })
              }}
              style={{ margin: '10px' }}
            >
              {t('ru.common.history_operations_btn')} <MenuOpenIcon style={{ margin: '0 10px' }} />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <b>{t('ru.tables.fields.status')}:</b>
            <Box display="flex">
              <Status active={!!table!.status}>
                {!!table!.status ? t('ru.common.status.active') : t('ru.common.status.inactive')}
              </Status>
            </Box>
          </Box>
        </Grid>
        {!!table!.currentGame && (
          <Grid item xs={12} md={12}>
            <Box>
              <b>{t('ru.tables.fields.current_game')}:</b> {!!table && table.currentGame!.name}
            </Box>
            <Box display="flex" justifyContent="center">
              <img className={classes.img} src={table!.currentGame!.logo} alt="" />
            </Box>
          </Grid>
        )}
      </>;
  const packets = () => {
    return (
      <Grid item xs={12}>
        <Box>
          <b>{t('ru.tables.tabs.packets')}:</b>
          <br />
        </Box>
        {table &&
          table.packages.map((item: IPackage, id: number) => {
            return (
              <React.Fragment key={id}>
                <ListItem>
                  <Box display="flex" alignItems="center">
                    <BorderOuterIcon className={classes.icon} />
                    {item.package.name}
                  </Box>
                </ListItem>
                <Collapse in={true}>
                  <Box className={classes.chips}>
                    {item.games.map((game: IGame) => {
                      return (
                        <Chip
                          className={classes.chip}
                          key={game.id}
                          label={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <span>{game.name}</span>
                            </Box>
                          }
                        />
                      )
                    })}
                  </Box>
                </Collapse>
              </React.Fragment>
            )
          })}
      </Grid>
    )
  }

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const canViewGamePackages = findPermission(items, 'viewGamePackageList');
  // const prevPath = !!location.state?.prevPath ? new URL(location.state.prevPath).search : null
  const rootRender =
    !table || loading ? (
      <Preloader absolute={false} />
    ) : (
      <>
        {location.state && location.state.prevPaths && !!location.state.prevPaths.length && (
          <GoBackBtn />
        )}
        <Box className={classes.closeBtn}>
          {findPermission(items, 'viewGamblingTable') &&
            findPermissionOr(items, ['updateGamblingTable', 'updateGamblingTableChildren']) && (
              <Tooltip
                title={
                  isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
                }
                placement="top"
              >
                <IconButton onClick={() => setIsChange(!isChange)}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      background: '#b6b6b6b6',
                      width: '30px',
                      minHeight: '30px',
                      borderRadius: '50%',
                      padding: '6px',
                    }}
                  >
                    <img
                      style={{
                        width: '20px',
                        minHeight: 'auto',
                      }}
                      src={!isChange ? editPng : visionPng}
                      alt="icon"
                    />
                  </Box>
                </IconButton>
              </Tooltip>
            )}
          <IconButton onClick={() => history.push('tables')}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <AccountCircleIcon fontSize="large" />
          <h2>{table?.name}</h2>
        </Box>
        {isChange ? (
          <TableForm
            onSubmit={(data, packages, hideForm, id, info) =>
              id && dispatch(handlerChangeTable(data, packages, hideForm, id, info))
            }
            id={table.id}
            name={table.name}
            hall_id={table.hall_id}
            packets={table.packages}
            status={table.status}
            cancelBtnText={t('ru.common.back_btn')}
            cancelBtnEvent={() => setIsChange(false)}
            submitBtnText={t('ru.common.submit_btn')}
            hideForm={() => setIsChange(false)}
            tab={tab}
            setTab={setTab}
            denomination={table.denomination}
            denominationList={table.denominationList}
          />
        ) : (
          <>
            <Box className="tabs" display="flex" justifyContent="flex-start" style={{ overflowX: 'auto' }}>
              <TabItem active={tab === 0} onClick={() => setTab(0)}>
                {t('ru.tables.tabs.info')}
              </TabItem>
              {table && <TabItem active={tab === 5} onClick={() => setTab(5)}>
                {t('ru.tables.tabs.denomination')}
              </TabItem>}
              {canViewGamePackages && <TabItem active={tab === 1} onClick={() => setTab(1)}>
                {t('ru.tables.tabs.packets')}
              </TabItem>}
              {userRole && userRole !== 'cashier' && (
                <TabItem active={tab === 2} onClick={() => setTab(2)}>
                  {t('ru.tables.tabs.jackpots')}
                </TabItem>
              )}
              {userRole && userRole !== 'cashier' && <TabItem active={tab === 4} onClick={() => setTab(4)}>
                {t('ru.tables.tabs.reports')}
              </TabItem>}
            </Box>
            <List>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  {returnTab(tab)}
                </Grid>
              </div>
            </List>
          </>
        )}
      </>
    )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Table
